/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { useEffect, useState } from "react";
import { AuthAPI } from "./apis/AuthAPI";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
      AuthAPI.getAccessToken()
      .catch((err) => {
        navigate("/sign-in")
        console.log(err)
      })
  }, [])

  return (

      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        
        <Route path="calls" element={<Main><Tables /></Main>} />
        <Route path="*" element={<Navigate to="/calls" replace />} />
      </Routes>

  );
}

export default App;


import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";

const signIn = async ({ email, password }) => {
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            const userMin = {
                email: user.email,
                uid: user.uid
            }

            const docRef = doc(db, "user-id-to-api", user.uid);

            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    console.log(docSnap.data()["api-key"])
                    userMin["api-key"] = docSnap.data()["api-key"]
                    localStorage.setItem("authedUser", JSON.stringify(userMin))
                    resolve(userMin)
                  } else {
                    // docSnap.data() will be undefined in this case
                    reject("API Key does not exist for user!")
                  }
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            reject(error)
        });
    }) 
    
}

const getAccessToken = async () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            unsubscribe();

            if (user == null) {
                reject("User is not logged in.")
            } else {
                let currentTokenStr = localStorage.getItem("accessToken")
                if (currentTokenStr != null) {
                    let currentToken = JSON.parse(currentTokenStr)

                    if (Date.now() < Date.parse(currentToken.expirationTime)) {
                        resolve(currentToken.token)
                    } else {
                        resolve(getNewToken(user))
                    }
                } else {
                    resolve(getNewToken(user))
                }
            }
        }, reject);
    });

}

const getNewToken = (user) => {
    return new Promise((res, rej) => {
        user.getIdTokenResult(true)
                    .then(function (idToken) {
                        console.log("Retrieved brand new access token!")
                        localStorage.setItem("accessToken", JSON.stringify(idToken))
                        res(idToken.token)
                    }).catch(function (error) {
                        console.log(error.code, error.message)
                        rej("Unable to fetch access token.")
                    });
    })
    
}

// TODO: Need to add API key data creation here
const signUp = async ({ email, password }) => {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        });
}

const signOutUser = async () => {
    console.log("123456")
    return new Promise((res,rej) => {
        signOut(auth).then(() => {
            localStorage.removeItem("accessToken")
            localStorage.removeItem("authedUser")
            console.log("removed")
            res();
        }).catch((error) => {
            // An error happened.
            rej(error)
        });
    })
}

export const AuthAPI = {
    signIn,
    signUp,
    signOutUser,
    getAccessToken
}
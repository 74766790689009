import axios from "axios"
import { AuthAPI } from "../apis/AuthAPI";

axios.defaults.baseURL =  'https://us-central1-raith-b6f91.cloudfunctions.net/api/'
axios.interceptors.request.use(
    async (config) => {
        let currentTokenStr = localStorage.getItem("accessToken")
        let currentAuthedUser = localStorage.getItem("authedUser")

        if (!currentTokenStr || !currentAuthedUser) {
            return Promise.reject('No authentication token');
        }

        if (currentTokenStr && currentAuthedUser) {
            let currentTokenObj = JSON.parse(currentTokenStr)
            let currentAuthedUserObj = JSON.parse(currentAuthedUser)
            let currentToken = currentTokenObj.token

            if (Date.now() > Date.parse(currentTokenObj.expirationTime)) {
                currentToken = await AuthAPI.getAccessToken();
                console.log("New Token: " + currentToken)
            }
            if(!currentToken) {
                throw "Unable to authenticate for HTTP Client."
            }
            config.headers.Authorization = `Bearer ${currentToken}`;
            config.headers["x-api-key"] = currentAuthedUserObj["api-key"]
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    }
);
// export const client = async () => {
//     let token = await AuthAPI.getAccessToken()
//     if (!token) {
//         throw "Cannot make request. Forbidden."
//     }
//     instance.defaults.headers.common["Authorization"] = `Bearer ${token}`
//     return instance
// }

export default axios

import { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import {
  Row,
  Col,
  Card,
  Space,
  Divider,
  Typography,
  List,
  Tag,
  Avatar,
  Timeline,
  Spin
} from "antd";
import { CallsAPIClient } from "../../clients/CallsAPIClient";
import AgentImage from "../../assets/images/agent.jpg"
import ReactAudioPlayer from 'react-audio-player';


const CallDetailModal = ({ isOpen, call_id }) => {

  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [callDetails, setCallDetails] = useState();
  const [baseDetails, setBaseDetails] = useState({});
  const [transcriptData, setTranscriptData] = useState([]);
  const [callId, setCallId] = useState(call_id)

  useEffect(() => {
    setIsModalOpen(isOpen)
    setCallId(call_id)
  }, [isOpen, call_id])

  useEffect(() => {
    if (callId) {
      CallsAPIClient.getCallDetails(callId)
        .then((details) => {
          console.log(details);
          setBaseDetails({
            "Call ID": details.id,
            "From": details.from,
            "To": details.to,
            "Status": statusToTag(details.status),
            "Duration": durationToCleanText(details.call_duration),
          })

          setTranscriptData(details.transcript)
          setCallDetails(details)
        })
    }
  }, [callId])


  const userToTag = (user) => {
    return user ? <Tag color={user == "USER" ? "grey" : "volcano"} key={user}>
      {user.toUpperCase()}
    </Tag> : ""
  }

  const statusToTag = (status) => {
    return <Tag color={status == "complete" ? "green" : "volcano"} key={status}>
      {status.toUpperCase()}
    </Tag>
  }

  const durationToCleanText = (duration) => {
    const min = Math.floor(duration)
    const sec = Math.floor((duration % 1) * 60)
    return `${min > 0 ? `${min} min` : ""} ${sec}s`
  }

  const handleClose = () => {
    setCallDetails(null)
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Call Details"
        visible={isModalOpen}
        footer={[
          <Button key="back" onClick={handleClose}>
            Close
          </Button>
        ]}
        onCancel={handleClose}
      >
        {callDetails ?
          (
            <>
              {Object.keys(baseDetails).map((key, i) => {
                return (
                  <Row key={i}>
                    <Col>
                      <Space size="medium" split={<Divider type="vertical" />}>
                        <Typography.Text strong>{key}</Typography.Text>
                        <Typography>{baseDetails[key]}</Typography>
                      </Space>
                    </Col>
                  </Row>
                )
              })}

              <Divider type="horizontal" />

              <Timeline>
                <Timeline.Item color='green'>Call started {callDetails.started_at ? <>at <b>{new Date(callDetails.started_at).toLocaleString('en-US', { timeZoneName: 'short' })}</b></>:<></>}</Timeline.Item>
                <Timeline.Item color='grey'>Call lasted {durationToCleanText(callDetails.call_duration)}</Timeline.Item>
                <Timeline.Item color='orange'>Call ended at <b>{new Date(callDetails.end_at).toLocaleString('en-US', { timeZoneName: 'short' })}</b> {callDetails.call_ended_by ? <>by {userToTag(callDetails.call_ended_by)}</> : "" }</Timeline.Item>
              </Timeline>

              <Divider type="horizontal" />

              {callDetails.isRecorded &&
                <>
                  <Typography.Text strong>Recording</Typography.Text>
                  <ReactAudioPlayer
                    src="https://api.twilio.com/2010-04-01/Accounts/ACbed526cc84d22837928b906e9f4cb96e/Calls/CAf0f42e0f68243b06f0935e73ac05d0e5/Recordings/RE487a0788701591f32bf2cdaa96e0a63e.mp3"
                    controls
                    className="hundred-width voice-recording-player"
                  />
                  <Divider type="horizontal" />
                </>
              }

              {transcriptData.length > 0 &&
                <List
                  size="large"
                  header={<div>Call Transcript</div>}
                  footer={<div><b>Call Ended by {callDetails && callDetails.call_ended_by}</b></div>}
                  bordered
                  dataSource={transcriptData}
                  renderItem={(item, _) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item.user == "user" ? `https://api.dicebear.com/7.x/miniavs/svg?seed=${0}` : AgentImage} />}
                        title={<a href="https://ant.design">{item.user.charAt(0).toUpperCase() + item.user.slice(1)}</a>}
                        description={item.text}
                      />
                    </List.Item>
                  )}
                />
              }
            </>
          ) :
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spin size='large' />
          </div>
        }
      </Modal>
    </>
  );
};

export default CallDetailModal;
/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Space, Tag,
  Spin
} from "antd";
import { useEffect, useState } from "react";
import { CallsAPIClient } from "../clients/CallsAPIClient";
import CallDetailModal from "../components/layout/CallDetailModal";
import { Modal } from 'antd';
import MissedCall from '../assets/images/missed-call.png'
import Clock from "../assets/images/clock.png"
import Telephone from "../assets/images/telephone.png"
import PhoneCall from "../assets/images/phone-call.png"

const { Title } = Typography;


function Tables() {
  const [callLogData, setCallLogData] = useState()
  const [inputData, setInputData] = useState({overview: {}, calls: []})
  const [selectedCallId, setSelectedCallId] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showOnlyFailed, setShowOnlyFailed] = useState(false)

  useEffect(async () => {
    let logs = await CallsAPIClient.getCallLog()
    const durationToCleanText = (duration) => {
      const min = Math.floor(duration)
      const sec = Math.floor((duration % 1) * 60)
      return `${min > 0 ? `${min} min` : ""} ${sec}s`
    }
    logs["overview"]["total_duration"] = durationToCleanText(logs["overview"]["total_duration"])
    //console.log(logs)

    setInputData(logs)
    setTableData(logs)
  }, [])

  const setTableData = (inputData) => {
    console.log("Setting table data...")
    console.log(inputData)
    let tableData = []

    for (let i = 0; i < inputData.calls.length; i++) {
      let call = inputData["calls"][i]
      
      if(showOnlyFailed && call["status"] == "complete") { continue; }

      tableData.push(
        {
          key: `${i}`,
          call_id: call["id"],
          from: call["from"],
          to: call["to"],
          started_at: call["started_at"],
          duration: {
            min: Math.floor(call["call_duration"]),
            sec: Math.floor((call["call_duration"] % 1) * 60)
          },
          status: call["status"]
        },
      )
    }

    console.log(tableData)
    setCallLogData(tableData)
  }

  useEffect(() => setTableData(inputData), [showOnlyFailed])

  const openCallDetails = (call_id) => {
    console.log("id " + call_id)
    setSelectedCallId(call_id)
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: 'Call ID',
      dataIndex: 'call_id',
      key: 'call_id',
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: 'Started At',
      dataIndex: 'started_at',
      key: 'started_at',
      render: (date) => <p>{new Date(date).toLocaleString('en-US', { timeZoneName: 'short' })}</p>
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (_, { duration }) => (
        <Tag>
          {duration["min"] > 0 && `${duration["min"]} min`} {duration["sec"]}s
        </Tag>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <Tag color={status == "complete" ? "green" : "volcano"} key={status}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'call_id',
      key: 'call_id',
      render: (_, { call_id }) => (
        <Space size="middle">
          <Button size="large" onClick={() => openCallDetails(call_id)}>Details</Button>
        </Space>
      ),
    },
  ];
  
  const overview = [
    {
      today: "Total Calls",
      title: inputData["overview"]["total_calls"],
      icon: Telephone,
      bnb: "bnb2",
    },
    {
      today: "Calls Answered",
      title: inputData["overview"]["total_answered"],
      icon: PhoneCall,
      bnb: "bnb2",
    },
    {
      today: "Calls Failed",
      title: inputData["overview"]["total_failed"],
      icon: MissedCall,
      bnb: "redtext",
    },
    {
      today: "Total Duration",
      title: inputData["overview"]["total_duration"],
      icon: Clock,
      bnb: "bnb2",
    },
  ];

  return (
    <>
      <div className="tabled">
      <Row className="rowgap-vbox" gutter={[24, 0]}>
          {overview.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <img className="overview-icon" src={c.icon} />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Call Log"
              extra={
                <>
                  <Radio.Group onChange={(v) => setShowOnlyFailed(v.target.value == "failed")} defaultValue="all">
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="failed">FAILED</Radio.Button>
                  </Radio.Group>
                </>
              }
            >
              {callLogData ?
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={callLogData}
                    pagination={true}
                    className="ant-border-space"
                  />
                  <CallDetailModal
                    isOpen={isModalOpen}
                    call_id={selectedCallId}
                  />
                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <Spin size='large' />
                </div>
              }

            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;

import axios from "./HTTPClient"

const getCallLog = async () => {
    return new Promise((res, rej) => {
        axios.get("/GetDashboard")
            .then(resp => res(resp.data))
            .catch(err => {
                if (typeof err === 'string') {
                    console.log('Request rejected:', err);
                } else {
                    rej(err)
                }
            })
    })
}

const getCallDetails = async (call_id) => {
    return new Promise((res, rej) => {
        axios.get(`/GetCall/${call_id}`)
            .then(resp => res(resp.data))
            .catch(err => {
                if (typeof err === 'string') {
                    console.log('Request rejected:', err);
                } else {
                    rej(err)
                }
            })
    })
}

export const CallsAPIClient = {
    getCallLog,
    getCallDetails
}